
@import "../src/assets/css/plugins.css";
@import "../src/assets/css/dark.css";
@import "../src/assets/css/style.css";

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/scrollbar";
@import "swiper/css/effect-fade";

html {
  scroll-behavior: smooth;
}
.c-pointer {
  cursor: pointer;
}

/* .anchor_nav li.current {
  position: relative;
  transition: 1s all;
}
.anchor_nav .current::after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--main-color);
  position: absolute;
  bottom: 0px;
  left: 0;
  transition: 1s all;
  display: block;
}
.dark .anchor_nav .current::after {
  content: "";
  background: #fff;
} */

/* image popup */
img.mfp-img {
  box-shadow: 0 0 8px rgb(0 0 0 / 60%);
  position: absolute;
  max-height: 392px;
  padding: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mfp-img-container .mfp-content {
  max-width: 400px !important;
}
.mfp-img-container .mfp-close {
  top: -110px;
  right: -24px;
}
.main-menu li.active > a,
.header-navigation .main-menu ul > li.has-children.active > a:after {
  color: #ff344f !important;
}

span.current {
  color: unset !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
